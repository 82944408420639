import { TemplateElement, html, defineElement, optionalAttribute } from '@webtides/element-js';
import paginate from '../../utils/paginate.js';
import i18n from '../../utils/i18n.js';
import style from './pagination.css';
import '../icon/icon.js';

/**
 * @property {number} totalItems
 * @property {number} currentPage
 * @property {number} pageSize
 * @property {number} maxPages
 * @property {string} currentPageParameterName
 */
export default class Pagination extends TemplateElement {
    constructor() {
        super({ styles: [style] });
    }

    properties() {
        return {
            totalItems: 0,
            currentPage: 1,
            pageSize: 10,
            maxPages: 5,
            pagination: undefined,
            currentPageParameterName: 'page',
        };
    }

    connected() {
        this.pagination = paginate(this.totalItems, this.currentPage, this.pageSize, this.maxPages);
    }

    get previousPage() {
        const previousPage = this.currentPage - 1;
        return previousPage < 1 ? 1 : previousPage;
    }

    get nextPage() {
        const nextPage = this.currentPage + 1;
        return nextPage > this.pagination?.totalPages ? this.pagination.totalPages : nextPage;
    }

    template() {
        const pageLinkActiveClasses = 'z-10 bg-white border border-gray-70';
        const pageLinkDefaultClasses = 'hover:bg-green-light25 active:opacity-50';

        return html`
            <div class="h-1 mb-24 bg-gray-20"></div>
            <div class="flex gap-16 flex-col sm:flex-row items-center justify-center sm:justify-between">
                <div class="text-sm text-gray-50">
                    ${i18n('pagination.showing')}
                    <span class="font-medium">${this.pagination?.startIndex + 1}</span>
                    ${i18n('pagination.to')}
                    <span class="font-medium">${this.pagination?.endIndex + 1}</span>
                    ${i18n('pagination.off')}
                    <span class="font-medium">${this.pagination?.totalItems}</span>
                    ${i18n('pagination.results')}
                </div>
                <div>
                    <nav class="relative z-0 inline-flex rounded-md gap-2" aria-label="${i18n('pagination.label')}">
                        <a
                            href="${`?${this.currentPageParameterName}=${this.previousPage}`}"
                            rel="prev"
                            class="relative inline-flex items-center px-4 py-2 text-sm font-medium rounded-md hover:bg-green-light25 active:opacity-50"
                        >
                            <span class="sr-only">${i18n('pagination.previous')}</span>
                            <mb-icon name="navigation--cta-arrow-left" class="h-16 w-16"></mb-icon>
                        </a>
                        ${(this.pagination?.pages || []).map(
                            (page) => html`
                                <a
                                    href="${`?${this.currentPageParameterName}=${page}`}"
                                    ${optionalAttribute(page === this.pagination?.currentPage, 'aria-current', 'page')}
                                    class="${page === this.pagination?.currentPage
                                        ? pageLinkActiveClasses
                                        : pageLinkDefaultClasses} relative inline-flex items-center px-4 py-2 text-sm font-medium rounded-md"
                                >
                                    ${page}
                                </a>
                            `,
                        )}
                        <a
                            href="${`?${this.currentPageParameterName}=${this.nextPage}`}"
                            rel="next"
                            class="relative inline-flex items-center px-4 py-2 text-sm font-medium rounded-md hover:bg-green-light25 active:opacity-50"
                        >
                            <span class="sr-only">${i18n('pagination.next')}</span>
                            <mb-icon name="navigation--cta-arrow-right" class="h-16 w-16"></mb-icon>
                        </a>
                    </nav>
                </div>
            </div>
        `;
    }
}

defineElement('mb-pagination', Pagination);
